

 import React from 'react';
 import { Formik, Form, Field } from 'formik';
 import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input, Button, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper
  } from "@chakra-ui/react"


import { ReservationApi, UserApi } from 'zhuangniagaraaxios'
import axios from 'axios';

function GuestForm(props) {
    function validateName(value) {
      let error
      if (!value) {
        error = "Name is required"
      }
      return error
    }
  
    return (
      <Formik
        initialValues={{ firstname: "", lastname: "", email: "", phone: "", address: "", comments: "", numAdults: 0, numChildren: 0 }}
        onSubmit={ async (values, actions) => {
          console.log(JSON.stringify(values, null, 2))
          let userDetails = {
            'email': values.email,
            'phone': values.phone,
            'firstName': values.firstname,
            'lastName': values.lastname
          }
          let axiosInstance = axios.create();
          let apiUserService = new UserApi(undefined, "https://18.191.213.95:8089/v2", axiosInstance);
          let apiResponse = await apiUserService.createUser(userDetails)
          console.log(apiResponse)
          let userId = apiResponse.data.userId
          let reservationDetails = {
            'adults': parseInt(values.numAdults),
            'checkin': props.startDate,
            'checkout': props.endDate,
            'userId': userId,
            'children': parseInt(values.numChildren)
          }
          let apiReservationService = new ReservationApi(undefined, "https://18.191.213.95:8089/v2", axiosInstance);
          apiResponse = apiReservationService.createReservation(reservationDetails);
          console.log(apiResponse);
          actions.setSubmitting(false)
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Field name="firstname" validate={validateName}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name} isRequired mb={5}>
                  <FormLabel htmlFor="firstname">First name</FormLabel>
                  <Input {...field} id="firstname" placeholder="firstname" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="lastname" validate={validateName}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name} isRequired mb={5}>
                  <FormLabel htmlFor="lastname">Last name</FormLabel>
                  <Input {...field} id="lastname" placeholder="lastname" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="email" validate={validateName}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name} isRequired mb={5}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input {...field} id="email" placeholder="email" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="phone" validate={validateName}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name} isRequired mb={5}>
                  <FormLabel htmlFor="phone">Phone</FormLabel>
                  <Input {...field} id="phone" placeholder="phone" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="numAdults">
            {({ field, form }) => (

              <FormControl id="numAdults" isRequired mb={5}>
              <FormLabel>Adults</FormLabel>
              <NumberInput max={50} min={0} id="numAdults" {...field} onChange={(val) =>form.setFieldValue(field.name, val)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            )}
            </Field>

            <Button
              mt={4}
              isLoading={props.isSubmitting}
              type="submit"
            >
              Request Booking
            </Button>
          </Form>
        )}
      </Formik>
    )
  }

  export default GuestForm