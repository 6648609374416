import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Table, Thead, Tr, Td, Th, Tfoot, Tbody, HStack, Spacer, ChakraProvider, Text, Button, Flex, extendTheme, Heading, Center, Box, Container, Grid, GridItem } from '@chakra-ui/react'
import dayjs from 'dayjs'

import Layout from "../components/layout"
import Seo from "../components/seo"
import DateRangePickerWrapper from '../components/DateRangePickerWrapper';
import GuestForm from "../components/GuestForm"
import withLocation from "../components/withLocation"

function BookingPage({ search }) {
  const params = search
  const [startDate, setStartdate] = useState(params.start)
  const [endDate, setEnddate] = useState(params.end)
  const [nights, setNights] = useState(0)

  useEffect(() => {
    if (startDate && endDate) {
      const sDate = dayjs(startDate)
      const eDate = dayjs(endDate)
      let n = eDate.diff(sDate, 'days')
      if (n > 0) {
        setNights(n)
      }
    }
  })


  function handleDateChange(start, end) {
    console.log(startDate)
    if (start) setStartdate(start.format("YYYY-MM-DD"))
    if (end) setEnddate(end.format("YYYY-MM-DD"))
  }

  return (
    <Layout>
    <Seo title="Booking" />

    <HStack mt="6" justify="center" spacing="48px">
      <DateRangePickerWrapper startDateId="startDate" endDateId="endDate" onDatesChange={handleDateChange}/>
      <Button>Search</Button>
    </HStack>
    <Flex>
      <Spacer />

      <Box w={[400, 700]}>

      { startDate && endDate && 
        <Box  borderWidth="1px" borderRadius="lg" mt={10}>
        <Grid
          p={5} gap={1}
          templateRows="repeat(4, 1fr)"
          templateColumns="repeat(2, 1fr)">
            <GridItem colSpan={2} rowSpan={3} fontSize="2xl"><b>Price details</b></GridItem>
            <GridItem>{nights} nights from {startDate} to {endDate}</GridItem>
            <GridItem align="right">${nights * 200}</GridItem>
            <GridItem>Cleaning Fee</GridItem>
            <GridItem align="right">$100</GridItem>
            <GridItem>Total</GridItem>
            <GridItem align="right">${nights * 200 + 100}</GridItem>
        </Grid>
    </Box>
    }

    { startDate && endDate && 
    <Box  borderWidth="1px" borderRadius="lg" mt={10} p={5}>
      <Text fontSize="2xl" mb={5}><b>Guest details</b></Text>
    <GuestForm startDate={startDate} endDate={endDate}/>
    </Box>
    }

    </Box>
      <Spacer />
    </Flex>

  </Layout>
  );
}

export default withLocation(BookingPage)
