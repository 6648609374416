"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Niagara Booking System
 * This is Niagara Booking System
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: zhouhengzhuang@hotmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.ReservationApi = exports.ReservationApiFactory = exports.ReservationApiFp = exports.ReservationApiAxiosParamCreator = exports.ListingApi = exports.ListingApiFactory = exports.ListingApiFp = exports.ListingApiAxiosParamCreator = exports.CalendarApi = exports.CalendarApiFactory = exports.CalendarApiFp = exports.CalendarApiAxiosParamCreator = exports.UserDetailsUserTypeEnum = exports.TransactionPaymentTypeEnum = exports.SafetyAmenity = exports.Rule = exports.ReservationDetailsReservationStatusEnum = exports.MediaMediaTypeEnum = exports.ListingDetailsPlaceTypeEnum = exports.ListingDetailsPropertyTypeEnum = exports.CalendarDetailsCalendarStatusEnum = exports.BedBedtypeEnum = exports.Amenity = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
var Amenity;
(function (Amenity) {
    Amenity["Essentials"] = "Essentials";
    Amenity["CookingBasics"] = "Cooking basics";
    Amenity["DeskWorkspace"] = "Desk/workspace";
    Amenity["Iron"] = "Iron";
    Amenity["HairDryer"] = "Hair dryer";
    Amenity["Shampoo"] = "Shampoo";
    Amenity["Hangers"] = "Hangers";
    Amenity["Tv"] = "TV";
    Amenity["Heating"] = "Heating";
    Amenity["Crib"] = "Crib";
    Amenity["Wifi"] = "Wifi";
    Amenity["Breakfast"] = "Breakfast";
    Amenity["AirConditioning"] = "Air conditioning";
    Amenity["Fireplace"] = "Fireplace";
    Amenity["PrivateEntrance"] = "Private entrance";
    Amenity["Dryer"] = "Dryer";
    Amenity["Washer"] = "Washer";
    Amenity["Parking"] = "Parking";
    Amenity["HotTub"] = "Hot tub";
    Amenity["Pool"] = "Pool";
    Amenity["Gym"] = "Gym";
    Amenity["Kitchen"] = "Kitchen";
    Amenity["HighChair"] = "High chair";
    Amenity["SelfCheckIn"] = "Self check-in";
    Amenity["PrivateBathroom"] = "Private bathroom";
    Amenity["Piano"] = "Piano";
    Amenity["Beachfront"] = "Beachfront";
    Amenity["Waterfront"] = "Waterfront";
    Amenity["SkinInSkiOut"] = "Skin-in/ski-out";
    Amenity["EvCharger"] = "EV charger";
})(Amenity = exports.Amenity || (exports.Amenity = {}));
/**
    * @export
    * @enum {string}
    */
var BedBedtypeEnum;
(function (BedBedtypeEnum) {
    BedBedtypeEnum["Double"] = "Double";
    BedBedtypeEnum["Queen"] = "Queen";
    BedBedtypeEnum["Single"] = "Single";
    BedBedtypeEnum["SofaBed"] = "Sofa bed";
    BedBedtypeEnum["King"] = "King";
    BedBedtypeEnum["SmallDouble"] = "Small double";
    BedBedtypeEnum["Couch"] = "Couch";
    BedBedtypeEnum["BunkBed"] = "Bunk bed";
    BedBedtypeEnum["FloorMattress"] = "Floor mattress";
    BedBedtypeEnum["Crib"] = "Crib";
    BedBedtypeEnum["ToddlerBed"] = "Toddler bed";
    BedBedtypeEnum["Hammock"] = "Hammock";
    BedBedtypeEnum["WaterBed"] = "Water bed";
})(BedBedtypeEnum = exports.BedBedtypeEnum || (exports.BedBedtypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var CalendarDetailsCalendarStatusEnum;
(function (CalendarDetailsCalendarStatusEnum) {
    CalendarDetailsCalendarStatusEnum["Available"] = "AVAILABLE";
    CalendarDetailsCalendarStatusEnum["Blocked"] = "BLOCKED";
    CalendarDetailsCalendarStatusEnum["Reserved"] = "RESERVED";
})(CalendarDetailsCalendarStatusEnum = exports.CalendarDetailsCalendarStatusEnum || (exports.CalendarDetailsCalendarStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingDetailsPropertyTypeEnum;
(function (ListingDetailsPropertyTypeEnum) {
    ListingDetailsPropertyTypeEnum["House"] = "House";
    ListingDetailsPropertyTypeEnum["Apartment"] = "Apartment";
    ListingDetailsPropertyTypeEnum["BedAndBreakfast"] = "Bed and breakfast";
    ListingDetailsPropertyTypeEnum["BoutiqueHotel"] = "Boutique hotel";
    ListingDetailsPropertyTypeEnum["Bungalow"] = "Bungalow";
    ListingDetailsPropertyTypeEnum["Cabin"] = "Cabin";
    ListingDetailsPropertyTypeEnum["Chalet"] = "Chalet";
    ListingDetailsPropertyTypeEnum["Condominium"] = "Condominium";
    ListingDetailsPropertyTypeEnum["Cottage"] = "Cottage";
    ListingDetailsPropertyTypeEnum["GuestSuite"] = "Guest suite";
    ListingDetailsPropertyTypeEnum["Guesthouse"] = "Guesthouse";
    ListingDetailsPropertyTypeEnum["Hostel"] = "Hostel";
    ListingDetailsPropertyTypeEnum["Hotel"] = "Hotel";
    ListingDetailsPropertyTypeEnum["Loft"] = "Loft";
    ListingDetailsPropertyTypeEnum["Resort"] = "Resort";
    ListingDetailsPropertyTypeEnum["ServicedApartment"] = "Serviced apartment";
    ListingDetailsPropertyTypeEnum["Townhouse"] = "Townhouse";
    ListingDetailsPropertyTypeEnum["Villa"] = "Villa";
    ListingDetailsPropertyTypeEnum["Others"] = "Others";
})(ListingDetailsPropertyTypeEnum = exports.ListingDetailsPropertyTypeEnum || (exports.ListingDetailsPropertyTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingDetailsPlaceTypeEnum;
(function (ListingDetailsPlaceTypeEnum) {
    ListingDetailsPlaceTypeEnum["EntirePlace"] = "Entire place";
    ListingDetailsPlaceTypeEnum["PrivateRoom"] = "Private room";
    ListingDetailsPlaceTypeEnum["SharedRoom"] = "Shared room";
})(ListingDetailsPlaceTypeEnum = exports.ListingDetailsPlaceTypeEnum || (exports.ListingDetailsPlaceTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var MediaMediaTypeEnum;
(function (MediaMediaTypeEnum) {
    MediaMediaTypeEnum["Image"] = "IMAGE";
    MediaMediaTypeEnum["Video"] = "VIDEO";
    MediaMediaTypeEnum["File"] = "FILE";
    MediaMediaTypeEnum["Others"] = "Others";
})(MediaMediaTypeEnum = exports.MediaMediaTypeEnum || (exports.MediaMediaTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ReservationDetailsReservationStatusEnum;
(function (ReservationDetailsReservationStatusEnum) {
    ReservationDetailsReservationStatusEnum["Booked"] = "BOOKED";
    ReservationDetailsReservationStatusEnum["Cancelled"] = "CANCELLED";
    ReservationDetailsReservationStatusEnum["Checkedout"] = "CHECKEDOUT";
})(ReservationDetailsReservationStatusEnum = exports.ReservationDetailsReservationStatusEnum || (exports.ReservationDetailsReservationStatusEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
var Rule;
(function (Rule) {
    Rule["NotSuitableForChildren212Years"] = "Not suitable for children (2-12 years)";
    Rule["NotSuitableForInfantsUnder2Years"] = "Not suitable for infants (Under 2 years)";
    Rule["NotSuitableForPets"] = "Not suitable for pets";
    Rule["NoSmokingAllowed"] = "No smoking allowed";
    Rule["NoEventsAllowed"] = "No events allowed";
    Rule["MustClimbStairs"] = "Must climb stairs";
    Rule["PotentialForNoise"] = "Potential for noise";
    Rule["PetSLiveOnProperty"] = "Pet(s) live on property";
    Rule["NoParkingOnProperty"] = "No parking on property";
    Rule["SomeSpacesAreShared"] = "Some spaces are shared";
    Rule["AmenityLimitations"] = "Amenity limitations";
    Rule["SurveillanceOrRecordingDevicesOnProperty"] = "Surveillance or recording devices on property";
    Rule["WeaponsOnProperty"] = "Weapons on property";
    Rule["DangerousAnimalsOnProperty"] = "Dangerous animals on property";
})(Rule = exports.Rule || (exports.Rule = {}));
/**
 *
 * @export
 * @enum {string}
 */
var SafetyAmenity;
(function (SafetyAmenity) {
    SafetyAmenity["SmokeDetector"] = "Smoke detector";
    SafetyAmenity["CarbonMonoxideDetector"] = "Carbon monoxide detector";
    SafetyAmenity["FirstAidKit"] = "First aid kit";
    SafetyAmenity["FireExtinguisher"] = "Fire extinguisher";
    SafetyAmenity["LockOnBedroomDoor"] = "Lock on bedroom door";
})(SafetyAmenity = exports.SafetyAmenity || (exports.SafetyAmenity = {}));
/**
    * @export
    * @enum {string}
    */
var TransactionPaymentTypeEnum;
(function (TransactionPaymentTypeEnum) {
    TransactionPaymentTypeEnum["Credit"] = "CREDIT";
    TransactionPaymentTypeEnum["Cash"] = "CASH";
    TransactionPaymentTypeEnum["Wechat"] = "WECHAT";
    TransactionPaymentTypeEnum["Alipay"] = "ALIPAY";
})(TransactionPaymentTypeEnum = exports.TransactionPaymentTypeEnum || (exports.TransactionPaymentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var UserDetailsUserTypeEnum;
(function (UserDetailsUserTypeEnum) {
    UserDetailsUserTypeEnum["Guest"] = "GUEST";
    UserDetailsUserTypeEnum["Host"] = "HOST";
    UserDetailsUserTypeEnum["Cohost"] = "COHOST";
    UserDetailsUserTypeEnum["Manager"] = "MANAGER";
})(UserDetailsUserTypeEnum = exports.UserDetailsUserTypeEnum || (exports.UserDetailsUserTypeEnum = {}));
/**
 * CalendarApi - axios parameter creator
 * @export
 */
exports.CalendarApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * create calendar entry
         * @summary create calendar entry
         * @param {CalendarDetails} calendarDetails calendar details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendar: function (calendarDetails, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'calendarDetails' is not null or undefined
                    common_1.assertParamExists('createCalendar', 'calendarDetails', calendarDetails);
                    localVarPath = "/calendar";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(calendarDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * delete a calendar entry
         * @summary delete a calendar entry
         * @param {number} calendarId calendar ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar: function (calendarId, ifMatch, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'calendarId' is not null or undefined
                    common_1.assertParamExists('deleteCalendar', 'calendarId', calendarId);
                    localVarPath = "/calendar/{calendarId}"
                        .replace("{" + "calendarId" + "}", encodeURIComponent(String(calendarId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * get calendar entry details
         * @summary get calendar entry details
         * @param {number} calendarId calendar ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendar: function (calendarId, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'calendarId' is not null or undefined
                    common_1.assertParamExists('getCalendar', 'calendarId', calendarId);
                    localVarPath = "/calendar/{calendarId}"
                        .replace("{" + "calendarId" + "}", encodeURIComponent(String(calendarId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * retrieve calendar entries
         * @summary retrieve calendar entries for each date
         * @param {string} startDate filter start date
         * @param {string} endDate filter end date
         * @param {number} [listingId] listing ID
         * @param {'AVAILABLE' | 'BLOCKED' | 'RESERVED'} [calendarStatus] calendar status
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'DATE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendars: function (startDate, endDate, listingId, calendarStatus, limit, page, sortBy, sortOrder, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'startDate' is not null or undefined
                    common_1.assertParamExists('listCalendars', 'startDate', startDate);
                    // verify required parameter 'endDate' is not null or undefined
                    common_1.assertParamExists('listCalendars', 'endDate', endDate);
                    localVarPath = "/calendar";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (startDate !== undefined) {
                        localVarQueryParameter['startDate'] = (startDate instanceof Date) ?
                            startDate.toISOString().substr(0, 10) :
                            startDate;
                    }
                    if (endDate !== undefined) {
                        localVarQueryParameter['endDate'] = (endDate instanceof Date) ?
                            endDate.toISOString().substr(0, 10) :
                            endDate;
                    }
                    if (listingId !== undefined) {
                        localVarQueryParameter['listingId'] = listingId;
                    }
                    if (calendarStatus !== undefined) {
                        localVarQueryParameter['calendarStatus'] = calendarStatus;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (page !== undefined) {
                        localVarQueryParameter['page'] = page;
                    }
                    if (sortBy !== undefined) {
                        localVarQueryParameter['sortBy'] = sortBy;
                    }
                    if (sortOrder !== undefined) {
                        localVarQueryParameter['sortOrder'] = sortOrder;
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * update calendar entry
         * @summary update calendar entry
         * @param {number} calendarId calendar ID
         * @param {CalendarDetails} calendarDetails calendar details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalenar: function (calendarId, calendarDetails, ifMatch, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'calendarId' is not null or undefined
                    common_1.assertParamExists('updateCalenar', 'calendarId', calendarId);
                    // verify required parameter 'calendarDetails' is not null or undefined
                    common_1.assertParamExists('updateCalenar', 'calendarDetails', calendarDetails);
                    localVarPath = "/calendar/{calendarId}"
                        .replace("{" + "calendarId" + "}", encodeURIComponent(String(calendarId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(calendarDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * CalendarApi - functional programming interface
 * @export
 */
exports.CalendarApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CalendarApiAxiosParamCreator(configuration);
    return {
        /**
         * create calendar entry
         * @summary create calendar entry
         * @param {CalendarDetails} calendarDetails calendar details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendar: function (calendarDetails, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createCalendar(calendarDetails, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * delete a calendar entry
         * @summary delete a calendar entry
         * @param {number} calendarId calendar ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar: function (calendarId, ifMatch, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteCalendar(calendarId, ifMatch, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * get calendar entry details
         * @summary get calendar entry details
         * @param {number} calendarId calendar ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendar: function (calendarId, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCalendar(calendarId, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * retrieve calendar entries
         * @summary retrieve calendar entries for each date
         * @param {string} startDate filter start date
         * @param {string} endDate filter end date
         * @param {number} [listingId] listing ID
         * @param {'AVAILABLE' | 'BLOCKED' | 'RESERVED'} [calendarStatus] calendar status
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'DATE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendars: function (startDate, endDate, listingId, calendarStatus, limit, page, sortBy, sortOrder, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listCalendars(startDate, endDate, listingId, calendarStatus, limit, page, sortBy, sortOrder, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * update calendar entry
         * @summary update calendar entry
         * @param {number} calendarId calendar ID
         * @param {CalendarDetails} calendarDetails calendar details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalenar: function (calendarId, calendarDetails, ifMatch, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateCalenar(calendarId, calendarDetails, ifMatch, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CalendarApi - factory interface
 * @export
 */
exports.CalendarApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CalendarApiFp(configuration);
    return {
        /**
         * create calendar entry
         * @summary create calendar entry
         * @param {CalendarDetails} calendarDetails calendar details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendar: function (calendarDetails, requestId, options) {
            return localVarFp.createCalendar(calendarDetails, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * delete a calendar entry
         * @summary delete a calendar entry
         * @param {number} calendarId calendar ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar: function (calendarId, ifMatch, requestId, options) {
            return localVarFp.deleteCalendar(calendarId, ifMatch, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * get calendar entry details
         * @summary get calendar entry details
         * @param {number} calendarId calendar ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendar: function (calendarId, requestId, options) {
            return localVarFp.getCalendar(calendarId, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * retrieve calendar entries
         * @summary retrieve calendar entries for each date
         * @param {string} startDate filter start date
         * @param {string} endDate filter end date
         * @param {number} [listingId] listing ID
         * @param {'AVAILABLE' | 'BLOCKED' | 'RESERVED'} [calendarStatus] calendar status
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'DATE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendars: function (startDate, endDate, listingId, calendarStatus, limit, page, sortBy, sortOrder, requestId, options) {
            return localVarFp.listCalendars(startDate, endDate, listingId, calendarStatus, limit, page, sortBy, sortOrder, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * update calendar entry
         * @summary update calendar entry
         * @param {number} calendarId calendar ID
         * @param {CalendarDetails} calendarDetails calendar details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalenar: function (calendarId, calendarDetails, ifMatch, requestId, options) {
            return localVarFp.updateCalenar(calendarId, calendarDetails, ifMatch, requestId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
var CalendarApi = /** @class */ (function (_super) {
    __extends(CalendarApi, _super);
    function CalendarApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * create calendar entry
     * @summary create calendar entry
     * @param {CalendarDetails} calendarDetails calendar details to create
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    CalendarApi.prototype.createCalendar = function (calendarDetails, requestId, options) {
        var _this = this;
        return exports.CalendarApiFp(this.configuration).createCalendar(calendarDetails, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * delete a calendar entry
     * @summary delete a calendar entry
     * @param {number} calendarId calendar ID
     * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    CalendarApi.prototype.deleteCalendar = function (calendarId, ifMatch, requestId, options) {
        var _this = this;
        return exports.CalendarApiFp(this.configuration).deleteCalendar(calendarId, ifMatch, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * get calendar entry details
     * @summary get calendar entry details
     * @param {number} calendarId calendar ID
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    CalendarApi.prototype.getCalendar = function (calendarId, requestId, options) {
        var _this = this;
        return exports.CalendarApiFp(this.configuration).getCalendar(calendarId, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * retrieve calendar entries
     * @summary retrieve calendar entries for each date
     * @param {string} startDate filter start date
     * @param {string} endDate filter end date
     * @param {number} [listingId] listing ID
     * @param {'AVAILABLE' | 'BLOCKED' | 'RESERVED'} [calendarStatus] calendar status
     * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
     * @param {number} [page] Page number. Example: &#x60;2&#x60;
     * @param {'DATE'} [sortBy] The field to sort by.
     * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    CalendarApi.prototype.listCalendars = function (startDate, endDate, listingId, calendarStatus, limit, page, sortBy, sortOrder, requestId, options) {
        var _this = this;
        return exports.CalendarApiFp(this.configuration).listCalendars(startDate, endDate, listingId, calendarStatus, limit, page, sortBy, sortOrder, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * update calendar entry
     * @summary update calendar entry
     * @param {number} calendarId calendar ID
     * @param {CalendarDetails} calendarDetails calendar details to update
     * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    CalendarApi.prototype.updateCalenar = function (calendarId, calendarDetails, ifMatch, requestId, options) {
        var _this = this;
        return exports.CalendarApiFp(this.configuration).updateCalenar(calendarId, calendarDetails, ifMatch, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CalendarApi;
}(base_1.BaseAPI));
exports.CalendarApi = CalendarApi;
/**
 * ListingApi - axios parameter creator
 * @export
 */
exports.ListingApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * create listing
         * @summary create listing
         * @param {ListingDetails} listingDetails listing details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListing: function (listingDetails, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingDetails' is not null or undefined
                    common_1.assertParamExists('createListing', 'listingDetails', listingDetails);
                    localVarPath = "/listing";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(listingDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * delete a listing
         * @summary delete a listing
         * @param {number} listingId listing ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListing: function (listingId, ifMatch, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('deleteListing', 'listingId', listingId);
                    localVarPath = "/listing/{listingId}"
                        .replace("{" + "listingId" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * get listing details
         * @summary get listing details
         * @param {number} listingId listing ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListing: function (listingId, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('getListing', 'listingId', listingId);
                    localVarPath = "/listing/{listingId}"
                        .replace("{" + "listingId" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * retrieve listings
         * @summary retrieve listings
         * @param {string} [location] Location of the properties. Example: &#x60;Niagara Falls, NY, USA&#x60;
         * @param {number} [numGuests] number of guests listing can accommodate
         * @param {number} [eastLat] the latitude of east boundary of an area
         * @param {number} [westLat] the latitude of west boundary of an area
         * @param {number} [northLng] the longitude of north boundary of an area
         * @param {number} [southLng] the longitude of south boundary of an area
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'LISTINGNAME'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listListings: function (location, numGuests, eastLat, westLat, northLng, southLng, limit, page, sortBy, sortOrder, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/listing";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (location !== undefined) {
                        localVarQueryParameter['location'] = location;
                    }
                    if (numGuests !== undefined) {
                        localVarQueryParameter['numGuests'] = numGuests;
                    }
                    if (eastLat !== undefined) {
                        localVarQueryParameter['eastLat'] = eastLat;
                    }
                    if (westLat !== undefined) {
                        localVarQueryParameter['westLat'] = westLat;
                    }
                    if (northLng !== undefined) {
                        localVarQueryParameter['northLng'] = northLng;
                    }
                    if (southLng !== undefined) {
                        localVarQueryParameter['southLng'] = southLng;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (page !== undefined) {
                        localVarQueryParameter['page'] = page;
                    }
                    if (sortBy !== undefined) {
                        localVarQueryParameter['sortBy'] = sortBy;
                    }
                    if (sortOrder !== undefined) {
                        localVarQueryParameter['sortOrder'] = sortOrder;
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * update listing
         * @summary update listing
         * @param {number} listingId listing ID
         * @param {ListingDetails} listingDetails listing details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListing: function (listingId, listingDetails, ifMatch, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('updateListing', 'listingId', listingId);
                    // verify required parameter 'listingDetails' is not null or undefined
                    common_1.assertParamExists('updateListing', 'listingDetails', listingDetails);
                    localVarPath = "/listing/{listingId}"
                        .replace("{" + "listingId" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(listingDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * upload an image or file to a listing
         * @summary upload an image or file to a listing
         * @param {number} listingId listing ID to upload
         * @param {object} [additionalMetadata] Additional Metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: function (listingId, additionalMetadata, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('uploadFile', 'listingId', listingId);
                    localVarPath = "/listing/{listingId}/upload"
                        .replace("{" + "listingId" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (additionalMetadata !== undefined) {
                        localVarQueryParameter['additionalMetadata'] = additionalMetadata;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ListingApi - functional programming interface
 * @export
 */
exports.ListingApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ListingApiAxiosParamCreator(configuration);
    return {
        /**
         * create listing
         * @summary create listing
         * @param {ListingDetails} listingDetails listing details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListing: function (listingDetails, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createListing(listingDetails, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * delete a listing
         * @summary delete a listing
         * @param {number} listingId listing ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListing: function (listingId, ifMatch, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteListing(listingId, ifMatch, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * get listing details
         * @summary get listing details
         * @param {number} listingId listing ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListing: function (listingId, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListing(listingId, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * retrieve listings
         * @summary retrieve listings
         * @param {string} [location] Location of the properties. Example: &#x60;Niagara Falls, NY, USA&#x60;
         * @param {number} [numGuests] number of guests listing can accommodate
         * @param {number} [eastLat] the latitude of east boundary of an area
         * @param {number} [westLat] the latitude of west boundary of an area
         * @param {number} [northLng] the longitude of north boundary of an area
         * @param {number} [southLng] the longitude of south boundary of an area
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'LISTINGNAME'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listListings: function (location, numGuests, eastLat, westLat, northLng, southLng, limit, page, sortBy, sortOrder, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listListings(location, numGuests, eastLat, westLat, northLng, southLng, limit, page, sortBy, sortOrder, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * update listing
         * @summary update listing
         * @param {number} listingId listing ID
         * @param {ListingDetails} listingDetails listing details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListing: function (listingId, listingDetails, ifMatch, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateListing(listingId, listingDetails, ifMatch, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * upload an image or file to a listing
         * @summary upload an image or file to a listing
         * @param {number} listingId listing ID to upload
         * @param {object} [additionalMetadata] Additional Metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: function (listingId, additionalMetadata, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadFile(listingId, additionalMetadata, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ListingApi - factory interface
 * @export
 */
exports.ListingApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ListingApiFp(configuration);
    return {
        /**
         * create listing
         * @summary create listing
         * @param {ListingDetails} listingDetails listing details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListing: function (listingDetails, requestId, options) {
            return localVarFp.createListing(listingDetails, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * delete a listing
         * @summary delete a listing
         * @param {number} listingId listing ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListing: function (listingId, ifMatch, requestId, options) {
            return localVarFp.deleteListing(listingId, ifMatch, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * get listing details
         * @summary get listing details
         * @param {number} listingId listing ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListing: function (listingId, requestId, options) {
            return localVarFp.getListing(listingId, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * retrieve listings
         * @summary retrieve listings
         * @param {string} [location] Location of the properties. Example: &#x60;Niagara Falls, NY, USA&#x60;
         * @param {number} [numGuests] number of guests listing can accommodate
         * @param {number} [eastLat] the latitude of east boundary of an area
         * @param {number} [westLat] the latitude of west boundary of an area
         * @param {number} [northLng] the longitude of north boundary of an area
         * @param {number} [southLng] the longitude of south boundary of an area
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'LISTINGNAME'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listListings: function (location, numGuests, eastLat, westLat, northLng, southLng, limit, page, sortBy, sortOrder, requestId, options) {
            return localVarFp.listListings(location, numGuests, eastLat, westLat, northLng, southLng, limit, page, sortBy, sortOrder, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * update listing
         * @summary update listing
         * @param {number} listingId listing ID
         * @param {ListingDetails} listingDetails listing details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListing: function (listingId, listingDetails, ifMatch, requestId, options) {
            return localVarFp.updateListing(listingId, listingDetails, ifMatch, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * upload an image or file to a listing
         * @summary upload an image or file to a listing
         * @param {number} listingId listing ID to upload
         * @param {object} [additionalMetadata] Additional Metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: function (listingId, additionalMetadata, options) {
            return localVarFp.uploadFile(listingId, additionalMetadata, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ListingApi - object-oriented interface
 * @export
 * @class ListingApi
 * @extends {BaseAPI}
 */
var ListingApi = /** @class */ (function (_super) {
    __extends(ListingApi, _super);
    function ListingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * create listing
     * @summary create listing
     * @param {ListingDetails} listingDetails listing details to create
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.createListing = function (listingDetails, requestId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).createListing(listingDetails, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * delete a listing
     * @summary delete a listing
     * @param {number} listingId listing ID
     * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.deleteListing = function (listingId, ifMatch, requestId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).deleteListing(listingId, ifMatch, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * get listing details
     * @summary get listing details
     * @param {number} listingId listing ID
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.getListing = function (listingId, requestId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).getListing(listingId, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * retrieve listings
     * @summary retrieve listings
     * @param {string} [location] Location of the properties. Example: &#x60;Niagara Falls, NY, USA&#x60;
     * @param {number} [numGuests] number of guests listing can accommodate
     * @param {number} [eastLat] the latitude of east boundary of an area
     * @param {number} [westLat] the latitude of west boundary of an area
     * @param {number} [northLng] the longitude of north boundary of an area
     * @param {number} [southLng] the longitude of south boundary of an area
     * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
     * @param {number} [page] Page number. Example: &#x60;2&#x60;
     * @param {'LISTINGNAME'} [sortBy] The field to sort by.
     * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listListings = function (location, numGuests, eastLat, westLat, northLng, southLng, limit, page, sortBy, sortOrder, requestId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listListings(location, numGuests, eastLat, westLat, northLng, southLng, limit, page, sortBy, sortOrder, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * update listing
     * @summary update listing
     * @param {number} listingId listing ID
     * @param {ListingDetails} listingDetails listing details to update
     * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.updateListing = function (listingId, listingDetails, ifMatch, requestId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).updateListing(listingId, listingDetails, ifMatch, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * upload an image or file to a listing
     * @summary upload an image or file to a listing
     * @param {number} listingId listing ID to upload
     * @param {object} [additionalMetadata] Additional Metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.uploadFile = function (listingId, additionalMetadata, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).uploadFile(listingId, additionalMetadata, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ListingApi;
}(base_1.BaseAPI));
exports.ListingApi = ListingApi;
/**
 * ReservationApi - axios parameter creator
 * @export
 */
exports.ReservationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * create reservation entry
         * @summary create reservation entry
         * @param {ReservationDetails} reservationDetails reservation details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReservation: function (reservationDetails, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reservationDetails' is not null or undefined
                    common_1.assertParamExists('createReservation', 'reservationDetails', reservationDetails);
                    localVarPath = "/reservation";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(reservationDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * delete a reservation entry
         * @summary delete a reservation entry
         * @param {number} reservationId reservation ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation: function (reservationId, ifMatch, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reservationId' is not null or undefined
                    common_1.assertParamExists('deleteReservation', 'reservationId', reservationId);
                    localVarPath = "/reservation/{reservationId}"
                        .replace("{" + "reservationId" + "}", encodeURIComponent(String(reservationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * get reservation entry details
         * @summary get reservation entry details
         * @param {number} reservationId reservation ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservation: function (reservationId, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reservationId' is not null or undefined
                    common_1.assertParamExists('getReservation', 'reservationId', reservationId);
                    localVarPath = "/reservation/{reservationId}"
                        .replace("{" + "reservationId" + "}", encodeURIComponent(String(reservationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * retrieve reservations
         * @summary retrieve reservations
         * @param {number} [listingId] listing ID
         * @param {number} [userId] user ID
         * @param {string} [afterDate] filter checkout date after this date
         * @param {string} [beforeDate] filter checkin date before this date
         * @param {'BOOKED' | 'CANCELLED' | 'CHECKEDOUT'} [reservationStatus] reservation status
         * @param {number} [belowPrice]
         * @param {number} [abovePrice]
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'LISTINGID' | 'USERID' | 'PRICE' | 'CHECKINDATE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReservations: function (listingId, userId, afterDate, beforeDate, reservationStatus, belowPrice, abovePrice, limit, page, sortBy, sortOrder, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/reservation";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (listingId !== undefined) {
                        localVarQueryParameter['listingId'] = listingId;
                    }
                    if (userId !== undefined) {
                        localVarQueryParameter['userId'] = userId;
                    }
                    if (afterDate !== undefined) {
                        localVarQueryParameter['afterDate'] = (afterDate instanceof Date) ?
                            afterDate.toISOString().substr(0, 10) :
                            afterDate;
                    }
                    if (beforeDate !== undefined) {
                        localVarQueryParameter['beforeDate'] = (beforeDate instanceof Date) ?
                            beforeDate.toISOString().substr(0, 10) :
                            beforeDate;
                    }
                    if (reservationStatus !== undefined) {
                        localVarQueryParameter['reservationStatus'] = reservationStatus;
                    }
                    if (belowPrice !== undefined) {
                        localVarQueryParameter['belowPrice'] = belowPrice;
                    }
                    if (abovePrice !== undefined) {
                        localVarQueryParameter['abovePrice'] = abovePrice;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (page !== undefined) {
                        localVarQueryParameter['page'] = page;
                    }
                    if (sortBy !== undefined) {
                        localVarQueryParameter['sortBy'] = sortBy;
                    }
                    if (sortOrder !== undefined) {
                        localVarQueryParameter['sortOrder'] = sortOrder;
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * update reservation entry
         * @summary update reservation entry
         * @param {number} reservationId reservation ID
         * @param {ReservationDetails} reservationDetails reservation details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservation: function (reservationId, reservationDetails, ifMatch, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reservationId' is not null or undefined
                    common_1.assertParamExists('updateReservation', 'reservationId', reservationId);
                    // verify required parameter 'reservationDetails' is not null or undefined
                    common_1.assertParamExists('updateReservation', 'reservationDetails', reservationDetails);
                    localVarPath = "/reservation/{reservationId}"
                        .replace("{" + "reservationId" + "}", encodeURIComponent(String(reservationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(reservationDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ReservationApi - functional programming interface
 * @export
 */
exports.ReservationApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ReservationApiAxiosParamCreator(configuration);
    return {
        /**
         * create reservation entry
         * @summary create reservation entry
         * @param {ReservationDetails} reservationDetails reservation details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReservation: function (reservationDetails, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createReservation(reservationDetails, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * delete a reservation entry
         * @summary delete a reservation entry
         * @param {number} reservationId reservation ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation: function (reservationId, ifMatch, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteReservation(reservationId, ifMatch, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * get reservation entry details
         * @summary get reservation entry details
         * @param {number} reservationId reservation ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservation: function (reservationId, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getReservation(reservationId, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * retrieve reservations
         * @summary retrieve reservations
         * @param {number} [listingId] listing ID
         * @param {number} [userId] user ID
         * @param {string} [afterDate] filter checkout date after this date
         * @param {string} [beforeDate] filter checkin date before this date
         * @param {'BOOKED' | 'CANCELLED' | 'CHECKEDOUT'} [reservationStatus] reservation status
         * @param {number} [belowPrice]
         * @param {number} [abovePrice]
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'LISTINGID' | 'USERID' | 'PRICE' | 'CHECKINDATE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReservations: function (listingId, userId, afterDate, beforeDate, reservationStatus, belowPrice, abovePrice, limit, page, sortBy, sortOrder, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listReservations(listingId, userId, afterDate, beforeDate, reservationStatus, belowPrice, abovePrice, limit, page, sortBy, sortOrder, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * update reservation entry
         * @summary update reservation entry
         * @param {number} reservationId reservation ID
         * @param {ReservationDetails} reservationDetails reservation details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservation: function (reservationId, reservationDetails, ifMatch, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateReservation(reservationId, reservationDetails, ifMatch, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ReservationApi - factory interface
 * @export
 */
exports.ReservationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ReservationApiFp(configuration);
    return {
        /**
         * create reservation entry
         * @summary create reservation entry
         * @param {ReservationDetails} reservationDetails reservation details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReservation: function (reservationDetails, requestId, options) {
            return localVarFp.createReservation(reservationDetails, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * delete a reservation entry
         * @summary delete a reservation entry
         * @param {number} reservationId reservation ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation: function (reservationId, ifMatch, requestId, options) {
            return localVarFp.deleteReservation(reservationId, ifMatch, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * get reservation entry details
         * @summary get reservation entry details
         * @param {number} reservationId reservation ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservation: function (reservationId, requestId, options) {
            return localVarFp.getReservation(reservationId, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * retrieve reservations
         * @summary retrieve reservations
         * @param {number} [listingId] listing ID
         * @param {number} [userId] user ID
         * @param {string} [afterDate] filter checkout date after this date
         * @param {string} [beforeDate] filter checkin date before this date
         * @param {'BOOKED' | 'CANCELLED' | 'CHECKEDOUT'} [reservationStatus] reservation status
         * @param {number} [belowPrice]
         * @param {number} [abovePrice]
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'LISTINGID' | 'USERID' | 'PRICE' | 'CHECKINDATE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReservations: function (listingId, userId, afterDate, beforeDate, reservationStatus, belowPrice, abovePrice, limit, page, sortBy, sortOrder, requestId, options) {
            return localVarFp.listReservations(listingId, userId, afterDate, beforeDate, reservationStatus, belowPrice, abovePrice, limit, page, sortBy, sortOrder, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * update reservation entry
         * @summary update reservation entry
         * @param {number} reservationId reservation ID
         * @param {ReservationDetails} reservationDetails reservation details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservation: function (reservationId, reservationDetails, ifMatch, requestId, options) {
            return localVarFp.updateReservation(reservationId, reservationDetails, ifMatch, requestId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ReservationApi - object-oriented interface
 * @export
 * @class ReservationApi
 * @extends {BaseAPI}
 */
var ReservationApi = /** @class */ (function (_super) {
    __extends(ReservationApi, _super);
    function ReservationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * create reservation entry
     * @summary create reservation entry
     * @param {ReservationDetails} reservationDetails reservation details to create
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    ReservationApi.prototype.createReservation = function (reservationDetails, requestId, options) {
        var _this = this;
        return exports.ReservationApiFp(this.configuration).createReservation(reservationDetails, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * delete a reservation entry
     * @summary delete a reservation entry
     * @param {number} reservationId reservation ID
     * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    ReservationApi.prototype.deleteReservation = function (reservationId, ifMatch, requestId, options) {
        var _this = this;
        return exports.ReservationApiFp(this.configuration).deleteReservation(reservationId, ifMatch, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * get reservation entry details
     * @summary get reservation entry details
     * @param {number} reservationId reservation ID
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    ReservationApi.prototype.getReservation = function (reservationId, requestId, options) {
        var _this = this;
        return exports.ReservationApiFp(this.configuration).getReservation(reservationId, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * retrieve reservations
     * @summary retrieve reservations
     * @param {number} [listingId] listing ID
     * @param {number} [userId] user ID
     * @param {string} [afterDate] filter checkout date after this date
     * @param {string} [beforeDate] filter checkin date before this date
     * @param {'BOOKED' | 'CANCELLED' | 'CHECKEDOUT'} [reservationStatus] reservation status
     * @param {number} [belowPrice]
     * @param {number} [abovePrice]
     * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
     * @param {number} [page] Page number. Example: &#x60;2&#x60;
     * @param {'LISTINGID' | 'USERID' | 'PRICE' | 'CHECKINDATE'} [sortBy] The field to sort by.
     * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    ReservationApi.prototype.listReservations = function (listingId, userId, afterDate, beforeDate, reservationStatus, belowPrice, abovePrice, limit, page, sortBy, sortOrder, requestId, options) {
        var _this = this;
        return exports.ReservationApiFp(this.configuration).listReservations(listingId, userId, afterDate, beforeDate, reservationStatus, belowPrice, abovePrice, limit, page, sortBy, sortOrder, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * update reservation entry
     * @summary update reservation entry
     * @param {number} reservationId reservation ID
     * @param {ReservationDetails} reservationDetails reservation details to update
     * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    ReservationApi.prototype.updateReservation = function (reservationId, reservationDetails, ifMatch, requestId, options) {
        var _this = this;
        return exports.ReservationApiFp(this.configuration).updateReservation(reservationId, reservationDetails, ifMatch, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ReservationApi;
}(base_1.BaseAPI));
exports.ReservationApi = ReservationApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
exports.UserApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * create user entry
         * @summary create user entry
         * @param {UserDetails} userDetails user details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (userDetails, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userDetails' is not null or undefined
                    common_1.assertParamExists('createUser', 'userDetails', userDetails);
                    localVarPath = "/user";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(userDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * delete a user entry
         * @summary delete a user entry
         * @param {number} userId user ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (userId, ifMatch, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    common_1.assertParamExists('deleteUser', 'userId', userId);
                    localVarPath = "/user/{userId}"
                        .replace("{" + "userId" + "}", encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * get user entry details
         * @summary get user entry details
         * @param {number} userId user ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (userId, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    common_1.assertParamExists('getUser', 'userId', userId);
                    localVarPath = "/user/{userId}"
                        .replace("{" + "userId" + "}", encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * list users
         * @summary list users
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'PHONE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: function (limit, page, sortBy, sortOrder, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (page !== undefined) {
                        localVarQueryParameter['page'] = page;
                    }
                    if (sortBy !== undefined) {
                        localVarQueryParameter['sortBy'] = sortBy;
                    }
                    if (sortOrder !== undefined) {
                        localVarQueryParameter['sortOrder'] = sortOrder;
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * update user entry
         * @summary update user entry
         * @param {number} userId user ID
         * @param {UserDetails} userDetails user details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (userId, userDetails, ifMatch, requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    common_1.assertParamExists('updateUser', 'userId', userId);
                    // verify required parameter 'userDetails' is not null or undefined
                    common_1.assertParamExists('updateUser', 'userDetails', userDetails);
                    localVarPath = "/user/{userId}"
                        .replace("{" + "userId" + "}", encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    if (requestId !== undefined && requestId !== null) {
                        localVarHeaderParameter['requestId'] = String(requestId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(userDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
exports.UserApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.UserApiAxiosParamCreator(configuration);
    return {
        /**
         * create user entry
         * @summary create user entry
         * @param {UserDetails} userDetails user details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (userDetails, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createUser(userDetails, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * delete a user entry
         * @summary delete a user entry
         * @param {number} userId user ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (userId, ifMatch, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteUser(userId, ifMatch, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * get user entry details
         * @summary get user entry details
         * @param {number} userId user ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (userId, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUser(userId, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * list users
         * @summary list users
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'PHONE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: function (limit, page, sortBy, sortOrder, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listUsers(limit, page, sortBy, sortOrder, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * update user entry
         * @summary update user entry
         * @param {number} userId user ID
         * @param {UserDetails} userDetails user details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (userId, userDetails, ifMatch, requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUser(userId, userDetails, ifMatch, requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
exports.UserApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.UserApiFp(configuration);
    return {
        /**
         * create user entry
         * @summary create user entry
         * @param {UserDetails} userDetails user details to create
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (userDetails, requestId, options) {
            return localVarFp.createUser(userDetails, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * delete a user entry
         * @summary delete a user entry
         * @param {number} userId user ID
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (userId, ifMatch, requestId, options) {
            return localVarFp.deleteUser(userId, ifMatch, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * get user entry details
         * @summary get user entry details
         * @param {number} userId user ID
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (userId, requestId, options) {
            return localVarFp.getUser(userId, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * list users
         * @summary list users
         * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
         * @param {number} [page] Page number. Example: &#x60;2&#x60;
         * @param {'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'PHONE'} [sortBy] The field to sort by.
         * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: function (limit, page, sortBy, sortOrder, requestId, options) {
            return localVarFp.listUsers(limit, page, sortBy, sortOrder, requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * update user entry
         * @summary update user entry
         * @param {number} userId user ID
         * @param {UserDetails} userDetails user details to update
         * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
         * @param {string} [requestId] unique request ID (UTC timestamp + random)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (userId, userDetails, ifMatch, requestId, options) {
            return localVarFp.updateUser(userId, userDetails, ifMatch, requestId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * create user entry
     * @summary create user entry
     * @param {UserDetails} userDetails user details to create
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.createUser = function (userDetails, requestId, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).createUser(userDetails, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * delete a user entry
     * @summary delete a user entry
     * @param {number} userId user ID
     * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.deleteUser = function (userId, ifMatch, requestId, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).deleteUser(userId, ifMatch, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * get user entry details
     * @summary get user entry details
     * @param {number} userId user ID
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getUser = function (userId, requestId, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).getUser(userId, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * list users
     * @summary list users
     * @param {number} [limit] The maximum number of results per page. Example: &#x60;10&#x60;
     * @param {number} [page] Page number. Example: &#x60;2&#x60;
     * @param {'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'PHONE'} [sortBy] The field to sort by.
     * @param {'ASC' | 'DESC'} [sortOrder] The sort order to use
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.listUsers = function (limit, page, sortBy, sortOrder, requestId, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).listUsers(limit, page, sortBy, sortOrder, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * update user entry
     * @summary update user entry
     * @param {number} userId user ID
     * @param {UserDetails} userDetails user details to update
     * @param {string} [ifMatch] concurrency control. only updated if etag value is matched
     * @param {string} [requestId] unique request ID (UTC timestamp + random)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateUser = function (userId, userDetails, ifMatch, requestId, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).updateUser(userId, userDetails, ifMatch, requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserApi;
}(base_1.BaseAPI));
exports.UserApi = UserApi;
